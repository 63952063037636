import React, { useState } from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import "./ChatApp.css";

const ChatApp = () => {
  const [messages, setMessages] = useState([]);

  const addMessage = (newMessages) => {
    console.log(newMessages);
    setMessages([...messages, ...newMessages]);
  };

  return (
    <div className="chat-app">
      <div className="message-list-container">
        <MessageList messages={messages} />
      </div>
      <div className="message-input-container">
        <MessageInput addMessage={addMessage} />
      </div>
    </div>
  );
};

export default ChatApp;
